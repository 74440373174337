import { CircularProgress, Stack } from '@mui/joy'
import { format, parseISO } from 'date-fns'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import { RentalContractPaymentSchedule } from '~/widgets/Driver/RentalContractControls'
import {
  EditRentalContract,
  editRentalContractModel,
} from 'src/features/RentalContracts/EditRentalContract'
import { carEditModel } from '~/features/Сars/EditCar'
import {
  rentalContractModel,
  Status,
  Print,
  useRentalContractMachineInterpreter,
  RentalContractMachineContext,
} from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { datetime } from '~/shared/config'
import { Breadcrumbs } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditRentalContractPage = () => {
  const params = useParams<{ rentalContractId: string; carId: string }>()
  const rentalContractId = params.rentalContractId as string
  const carId = params.carId

  const rentalContractError =
    rentalContractModel.useRentalContractError(rentalContractId)

  const { canAuditsView } = usePermissions()

  const isLoading = useStore(rentalContractModel.requestFx.pending)

  const rentalContract = useStore(editRentalContractModel.$rentalContract)
  const rentalContractCarId = rentalContract?.getCarId()
  const rentalFilePath = rentalContract?.getFilepath()
  const status = rentalContract?.getStatus()
  const number = rentalContract?.getNumber()
  const createdAt = rentalContract?.getCreatedAt()

  const interpreter = useRentalContractMachineInterpreter(status)
  const rentalContractMachineCtxValue = useMemo(
    () => ({ interpreter }),
    [interpreter],
  )

  const title = `${number} от ${
    createdAt ? format(parseISO(createdAt), datetime.uiDateFormat) : ''
  }`

  const car = useStore(carEditModel.$car)
  const carVin = useStoreMap(carEditModel.$car, (car) => car?.getVin())
  const carPlateNumber = useStoreMap(carEditModel.$car, (car) =>
    car?.getPlateNumber(),
  )
  const carTitle = car && `${carVin} [${carPlateNumber || '-'}]`

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Автомобили',
        link: '/cars',
      },
      {
        title: carTitle || 'Автомобиль ...',
        link: `/cars/${carId}/details`,
      },
      { title },
    ],
    [carId, carTitle, title],
  )

  if (rentalContractError) {
    return <NotFound message='Не удалось загрузить данные договора' />
  }

  if (
    !isLoading &&
    rentalContract &&
    rentalContract.getApiId() === rentalContractId &&
    carId !== rentalContractCarId
  ) {
    return (
      <NotFound
        message={`Загруженный договор [${rentalContractId}] не соответствует текущему автомобилю [${carId}]`}
      />
    )
  }

  return (
    <RentalContractMachineContext.Provider
      value={rentalContractMachineCtxValue}
    >
      <PageContainer title='Карточка договора'>
        <Container isHeader>
          <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />

          <Status isLoading={isLoading} />

          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-end'
            spacing={2}
          >
            <PageTitle loading={isLoading}>{title}</PageTitle>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <Stack
                data-testid='car-rental-contracts-actions'
                direction='row'
                spacing={2}
              >
                {canAuditsView && (
                  <AuditsModal auditableId={rentalContractId} />
                )}
                {rentalFilePath && <Print filepath={rentalFilePath} />}

                <RentalContractPaymentSchedule
                  rentalContractId={rentalContractId}
                  isIcon
                />
              </Stack>
            )}
          </Stack>
        </Container>
        <EditRentalContract
          rentalContractId={rentalContractId}
          canActions={false}
          canEdit={false}
        />
      </PageContainer>
    </RentalContractMachineContext.Provider>
  )
}
