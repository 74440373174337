import {
  RentalContractCanceled,
  RentalContractContinue,
  RentalContractSigning,
  RentalContractSuspend,
  RentalContractTerminate,
} from '~/widgets/Driver/RentalContractControls'
import { getCanRentalContractMachine } from '~/entities/RentalContract'
import { RentalContractStatusEnum } from '~/shared/config/enums'

type ActionsProps = {
  rentalContractId: UniqueId
  status: RentalContractStatusEnum
}

export function Actions({ status, rentalContractId }: ActionsProps) {
  const canStatus = getCanRentalContractMachine(status)
  return (
    <>
      {canStatus?.signing && (
        <RentalContractSigning isIcon rentalContractId={rentalContractId} />
      )}
      {canStatus?.canceled && (
        <RentalContractCanceled isIcon rentalContractId={rentalContractId} />
      )}
      {canStatus?.terminate && (
        <RentalContractTerminate isIcon rentalContractId={rentalContractId} />
      )}
      {canStatus?.suspend && (
        <RentalContractSuspend isIcon rentalContractId={rentalContractId} />
      )}
      {canStatus?.continue && (
        <RentalContractContinue isIcon rentalContractId={rentalContractId} />
      )}
    </>
  )
}
