import { Stack, Skeleton, Divider } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { rentalContractCreatedControlModel } from '~/widgets/Driver/RentalContractControls/RentalContractCreated'
import { driverChecksModel } from '~/features/Drivers/DriverChecks'
import {
  driverModel,
  useDriverMachineInterpreter,
  DriverMachineContext,
  driverMachine,
} from 'src/entities/Driver'

import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { ChipStatus } from '~/shared/ui/Chips'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { ActionButtons } from './ui/ActionButtons'
import { Alerts } from './ui/Alerts'
import { ExperienceDays } from './ui/ExperienceDays'
import { MainBalance } from './ui/MainBalance'
import { useConfigTabs } from './useConfigTabs'

export function DriverPage() {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  useGate(driverModel.Gate, { id: driverId })
  useGate(rentalContractCreatedControlModel.GetRentalContractActiveGate)
  useGate(driverChecksModel.Gate, { driverId })

  const configTabs = useConfigTabs(driverId)
  const driver = useStore(driverModel.$driver)
  const isLoading = useStore(driverModel.requestDriverFx.pending)

  const driverFullName = driver?.getFullName()
  const driverStatus = driver?.getStatus()

  const driverMachineInterpreter = useDriverMachineInterpreter(driverStatus)
  const driverMachineCtxValue = useMemo(
    () => ({ interpreter: driverMachineInterpreter }),
    [driverMachineInterpreter],
  )
  const statusNode = driverStatus
    ? driverMachine.getStateNode(driverStatus).meta
    : null

  const title = `Карточка водителя ${isLoading ? '...' : driverFullName}`

  const breadcrumbs = useMemo(
    (): BreadcrumbsList => [
      {
        title: 'Водители',
        link: '/drivers',
      },
      { title: driverFullName || '...' },
    ],
    [driverFullName],
  )

  const driverError = driverModel.useDriverError(driverId)
  if (driverError) {
    return <NotFound message='Не удалось загрузить водителя' />
  }

  return (
    <DriverMachineContext.Provider value={driverMachineCtxValue}>
      <PageContainer title={title}>
        <RoutesTabs
          parentPath={`/drivers/${driverId}`}
          config={configTabs}
          parentContent={
            <Container isHeader>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
                <Stack
                  direction='row'
                  divider={<Divider orientation='vertical' />}
                  spacing={2}
                >
                  <MainBalance />
                  <ExperienceDays />
                </Stack>
              </Stack>

              <ChipStatus
                data-testid='driver-status'
                variant={statusNode?.color || 'gray'}
                label={
                  isLoading ? (
                    <Skeleton sx={{ top: 0, left: 0, minWidth: '70px' }} />
                  ) : (
                    statusNode?.label || ''
                  )
                }
                sx={{ minWidth: '70px', marginBottom: '6px' }}
              />

              <Stack
                direction='row'
                alignItems='end'
                justifyContent='space-between'
              >
                <PageTitle loading={isLoading}>{driverFullName}</PageTitle>
                <ActionButtons driverId={driverId} loading={isLoading} />
              </Stack>
              <br />
              <Alerts driverId={driverId} />
            </Container>
          }
        />
      </PageContainer>
    </DriverMachineContext.Provider>
  )
}
