import { Skeleton } from '@mui/joy'
import { useRentalContractMachineContext } from '~/entities/RentalContract'
import { ChipStatus } from '~/shared/ui/Chips'

type StatusProps = {
  isLoading: boolean
}

export function Status({ isLoading }: StatusProps) {
  const { meta } = useRentalContractMachineContext()

  return (
    <ChipStatus
      data-testid='rental-contract-status'
      label={
        isLoading ? (
          <Skeleton sx={{ top: 0, left: 0, minWidth: '100px' }} />
        ) : (
          meta.label
        )
      }
      variant={meta.color}
      sx={{ minWidth: '100px', marginBottom: '6px' }}
    />
  )
}
