import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { rentalContractModel } from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { backFx } from '~/shared/lib/history'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { SlashCircleIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { TerminateModal } from '../TerminateModal'

type RentalContractTerminateProps = {
  rentalContractId: UniqueId
  isButton?: boolean
  isIcon?: boolean
  goBackOnTermination?: boolean
}

export const RentalContractTerminate = ({
  rentalContractId,
  isButton,
  isIcon,
  goBackOnTermination,
}: RentalContractTerminateProps) => {
  const { canRentalContractsCreate } = usePermissions()

  const { isModalShow, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const handleRentalContractTerminate = useCallback(
    async (reasons: string[]) => {
      await rentalContractModel.terminateRentalContractFx({
        rentalContractId: rentalContractId,
        reasons,
      })

      goBackOnTermination
        ? backFx()
        : await queryClient.invalidateQueries({
            queryKey: ['rental-contracts-list'],
          })
    },
    [goBackOnTermination, queryClient, rentalContractId],
  )

  if (!canRentalContractsCreate) return null

  return (
    <>
      <TerminateModal
        onOk={handleRentalContractTerminate}
        onCancel={closeModal}
        isShow={isModalShow}
      />
      {isButton && (
        <Button
          variant='red'
          startDecorator={<SlashCircleIcon color={myPalette.white.main} />}
          onClick={openModal}
        >
          Расторгнуть договор
        </Button>
      )}
      {isIcon && (
        <IconButton
          onClick={openModal}
          tooltipProps={{ title: 'Расторгнуть договор' }}
        >
          <SlashCircleIcon color={myPalette.red['700']} />
        </IconButton>
      )}
    </>
  )
}
