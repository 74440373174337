import { combine, createDomain, sample } from 'effector'
import { isString } from 'xstate/es/utils'
import { driverModel } from '~/entities/Driver'
import { $isFirstRentalContract } from '~/entities/RentalContract/model'
import { FormValues } from '~/entities/RentalContract/ui/Form'
import { AxiosErrorType, Media, RentalContract } from '~/shared/api'
import { formatDateTimeForApi } from '~/shared/lib/date'
import { openBlobFile } from '~/shared/lib/file/openBlobFile'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { getRoundedExpirationTime } from '~/shared/ui/Form/DateTimePickerInput'

export const domain = createDomain('features.rentalContract.create')

export const createFx = domain.createEffect<
  FormValues,
  RentalContract,
  AxiosErrorType
>({
  async handler(values) {
    const {
      carOption,
      driverId,
      workSchedule,
      dayOff,
      rentalServicesOptions,
      ...attr
    } = values

    const rentalContract = new RentalContract({
      ...(isString(workSchedule) && { workSchedule }),
      ...(isString(dayOff) && { dayOff }),
      ...attr,
    })

    if (isString(carOption)) rentalContract.setCar(carOption)
    if (isString(driverId)) rentalContract.setDriver(driverId)
    if (Array.isArray(rentalServicesOptions)) {
      rentalContract.setRentalServices(rentalServicesOptions.filter(isString))
    }

    const result = await rentalContract.save()
    return result.getModel() as RentalContract
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Договор сформирован',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const requestFileFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(filepath) {
    const response = await Media.getMedia(filepath)
    openBlobFile(response)
  },
})

sample({
  clock: createFx.doneData,
  fn(rentalContract) {
    return rentalContract.getFilepath() as UniqueId
  },
  target: requestFileFx,
})

sample({
  clock: requestFileFx.doneData,
  target: backFx,
})

sample({
  clock: requestFileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $defaultValues = combine(
  driverModel.$driver,
  $isFirstRentalContract,
  (driver, isFirstRentalContract) => {
    return {
      driverFullName: driver?.getFullName(),
      driverId: driver?.getApiId(),
      ...(isFirstRentalContract ? { firstDayIsFree: true } : {}),
      bookedUntil: formatDateTimeForApi(
        getRoundedExpirationTime(new Date(), 2),
      ),
    }
  },
)
