import { Stack } from '@mui/joy'
import { GridColDef } from '@mui/x-data-grid'
import { RentalContractPaymentSchedule } from '~/widgets/Driver/RentalContractControls'
import { ColumnDate, ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { Print } from '../Print'
import { Actions } from './Actions'
import { GridRow } from './types'

export const useColumns = (canActions = true): GridColDef<GridRow>[] => [
  {
    headerName: 'Номер договора',
    field: 'contractNumber',
    sortable: false,
    minWidth: 200,
    renderCell: ({ row }) => (
      <ColumnLink to={row.id}>{row.contractNumber}</ColumnLink>
    ),
  },
  {
    headerName: 'Дата заключения',
    field: 'createdAt',
    sortable: false,
    minWidth: 160,
    renderCell: ({ row }) => <ColumnDate type='date' value={row.createdAt} />,
  },
  {
    headerName: 'Дата расторжения',
    field: 'terminationDate',
    sortable: false,
    minWidth: 160,
    renderCell: ({ row }) => (
      <ColumnDate type='date' value={row.terminationDate} />
    ),
  },
  {
    headerName: 'Гос. номер',
    field: 'plateNumber',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Арендодатель',
    field: 'carRentalName',
    sortable: false,
    minWidth: 230,
    flex: 1,
  },
  {
    headerName: 'Статус',
    field: 'statusObj',
    sortable: false,
    minWidth: 180,
    renderCell: ({ value }) => (
      <ChipStatus label={value.label} variant={value.color} />
    ),
  },
  {
    headerName: 'Действия',
    field: 'actions',
    sortable: false,
    minWidth: 260,
    renderCell: ({ row }) => (
      <Stack
        direction='row'
        alignItems='center'
        flexWrap='wrap'
        data-testid='car-rental-contracts-actions'
      >
        {canActions && (
          <Actions status={row.status} rentalContractId={row.id} />
        )}

        <RentalContractPaymentSchedule isIcon rentalContractId={row.id} />

        <Print filepath={row.filepath} />
      </Stack>
    ),
  },
]
