import { GridColDef } from '@mui/x-data-grid'
import { useCallback } from 'react'
import { getMeta } from '~/entities/RentalContract'
import { RentalContract } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

type ListProps = {
  driverId?: UniqueId
  carId?: UniqueId
  isHasButtonTerminate?: boolean
  columns: GridColDef<GridRow>[]
}

export const List = ({ driverId, carId, columns }: ListProps) => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      let builder = RentalContract.limit(pageSize)
        .with('car')
        .with('driver')
        .orderBy('-createdAt')

      if (driverId) builder = builder.where('driverId', driverId)
      if (carId) builder = builder.where('carId', carId)

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((rentalContract) => {
        const meta = getMeta(rentalContract.getStatus())

        return {
          ...rentalContract.getAttributes(),
          id: rentalContract.getApiId() as string,
          carVin: rentalContract?.getCar()?.getVin(),
          createdAt: rentalContract?.getCreatedAt(),
          contractNumber: rentalContract?.getNumber(),
          plateNumber: rentalContract?.getCar()?.getPlateNumber(),
          carBrandAndModelTitle: rentalContract?.getCarBrandAndModel() ?? '',
          carRentalName: rentalContract?.getCarRentalName() ?? '',
          driverFullName: rentalContract?.getDriverFullName(),
          driverId: rentalContract?.getDriver()?.getApiId() as string,
          statusObj: {
            label: meta.label,
            color: meta.color,
          },
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [carId, driverId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Договоры'
      gridKey='rental-contracts-list'
      nestedGridKey={driverId || carId || ''}
      fetchRows={fetchRows}
      columns={columns}
    />
  )
}
