import { useCallback, useState } from 'react'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { ClockBrandIcon, ClockIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { PaymentScheduleModal } from './PaymentScheduleModal'
import { paymentScheduleRentalContractFx } from './PaymentScheduleModal/model'

type RentalContractPaymentScheduleProps = {
  rentalContractId: UniqueId
  isButton?: boolean
  isIcon?: boolean
}

export const RentalContractPaymentSchedule = ({
  rentalContractId,
  isButton,
  isIcon,
}: RentalContractPaymentScheduleProps) => {
  const { isModalShow, closeModal, openModal } = useModal()
  const [isLoading, toggleIsLoading] = useState<boolean>(false)

  const handleOnOpenModal = useCallback(async () => {
    try {
      toggleIsLoading(true)
      await paymentScheduleRentalContractFx(rentalContractId)
      openModal()
    } finally {
      toggleIsLoading(false)
    }
  }, [openModal, rentalContractId])

  return (
    <>
      <PaymentScheduleModal onCancel={closeModal} isShow={isModalShow} />
      {isButton && (
        <Button
          variant='gray'
          startDecorator={<ClockBrandIcon />}
          onClick={handleOnOpenModal}
          loading={isLoading}
        >
          График списаний
        </Button>
      )}
      {isIcon && (
        <IconButton
          onClick={handleOnOpenModal}
          tooltipProps={{ title: 'График списаний' }}
          loading={isLoading}
        >
          <ClockIcon />
        </IconButton>
      )}
    </>
  )
}
