import { useFormContext } from 'react-hook-form'
import { Region } from '~/shared/api'
import { AsyncAutocompleteInput } from '~/shared/ui/Form'

type Props = {
  showSkeleton: boolean | undefined
  disabled: boolean | undefined
}

export function RegionAsyncAutocompleteInput({
  showSkeleton,
  disabled,
}: Props) {
  const { watch } = useFormContext()

  const locationOption = watch('locationId')
  const regionId = locationOption?.filters?.regionId

  return (
    <AsyncAutocompleteInput
      label='Регион'
      name='regionId'
      placeholder='Выберите регион'
      skeletonShow={showSkeleton}
      readOnly={disabled}
      fetchOptions={(search) => Region.fetchOptions(search, regionId)}
      queryKey={regionId ? [regionId] : []}
    />
  )
}
