import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { rentalContractModel } from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { CanceledIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { CanceledModal } from '../CanceledModal'

type RentalContractCanceledProps = {
  rentalContractId: UniqueId
  isButton?: boolean
  isIcon?: boolean
}

export const RentalContractCanceled = ({
  rentalContractId,
  isButton,
  isIcon,
}: RentalContractCanceledProps) => {
  const { canRentalContractsCreate, canRentalContractsUpdate } =
    usePermissions()

  const { isModalShow, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const handleSuspend = useCallback(async () => {
    await rentalContractModel.canceledRentalContractFx(rentalContractId)
    await queryClient.invalidateQueries({
      queryKey: ['rental-contracts-list'],
    })
  }, [queryClient, rentalContractId])

  if (!canRentalContractsCreate && !canRentalContractsUpdate) return null

  return (
    <>
      <CanceledModal
        onOk={handleSuspend}
        onCancel={closeModal}
        isShow={isModalShow}
      />
      {isButton && (
        <Button
          variant='textRed'
          startDecorator={<CanceledIcon />}
          onClick={openModal}
        >
          Аннулировать договор
        </Button>
      )}
      {isIcon && (
        <IconButton
          tooltipProps={{ title: 'Аннулировать договор' }}
          onClick={openModal}
        >
          <CanceledIcon color={myPalette.red['600']} />
        </IconButton>
      )}
    </>
  )
}
