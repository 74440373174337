import { createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import { AxiosErrorType, RentalContract } from '~/shared/api'
import { CarAtWorkSubStatusEnum } from '~/shared/config/enums'
import { pushFx } from '~/shared/lib/history'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('entities.rentalContract')

export const requestFx = domain.createEffect<UniqueId, RentalContract>({
  handler: fetchRentalContract,
})

export const unseenRequestFx = domain.createEffect<UniqueId, RentalContract>({
  handler: fetchRentalContract,
})

export const saveFx = domain.createEffect<
  RentalContract,
  RentalContract,
  AxiosErrorType
>({
  async handler(rentalContract) {
    await rentalContract.save()
    return fetchRentalContract(rentalContract.getApiId() as UniqueId)
  },
})

const {
  $cache: $rentalContractCache,
  useCache: useRentalContractCache,
  updateCache,
} = createCache<RentalContract>({
  domain,
  getEntityId: (rentalContract) => rentalContract.getApiId() as UniqueId,
})
export { $rentalContractCache, useRentalContractCache }

$rentalContractCache
  .on([requestFx.doneData, unseenRequestFx.doneData], (cache, rentalContract) =>
    updateCache(cache, [rentalContract]),
  )
  .on(saveFx.doneData, (cache, rentalContract) =>
    updateCache(cache, [rentalContract], true),
  )

export const $rentalContractError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(
    [requestFx.fail, unseenRequestFx.fail],
    (store, { error, params: id }) => ({
      [id]: error,
      ...store,
    }),
  )

export const useRentalContractError = (id: UniqueId) =>
  useStoreMap($rentalContractError, (errors) => errors[id])

async function fetchRentalContract(id: UniqueId) {
  const response = await RentalContract.with('responsibleManager')
    .with('driver')
    .with('rentalServices')
    .find(id)
  return response.getData() as RentalContract
}

// CheckIsFirstRentalContract
export const CheckIsFirstRentalContractGate = createGate()

export const checkIsFirstRentalContract = domain.createEvent()
export const checkIsFirstRentalContractFx = domain.createEffect<
  string,
  boolean
>({
  async handler(driverId: UniqueId) {
    const rentalContracts = await fetchRentalContactList(driverId)
    return Boolean(!rentalContracts?.length)
  },
})

export const $checkIsFirstRentalContractIsLoading = domain
  .createStore(true)
  .on(
    [checkIsFirstRentalContractFx.doneData, checkIsFirstRentalContractFx.fail],
    () => false,
  )
  .on(CheckIsFirstRentalContractGate.close, () => true)

export const toggleIsFirstRentalContract = domain.createEvent<boolean>()
export const $isFirstRentalContract = domain
  .createStore<boolean>(false)
  .on(toggleIsFirstRentalContract, (_, value) => value)

sample({
  clock: CheckIsFirstRentalContractGate.open,
  target: checkIsFirstRentalContract,
})

sample({
  clock: CheckIsFirstRentalContractGate.close,
  fn: () => false,
  target: toggleIsFirstRentalContract,
})

sample({
  clock: checkIsFirstRentalContractFx.doneData,
  target: toggleIsFirstRentalContract,
})

async function fetchRentalContactList(driverId: UniqueId) {
  const response = await RentalContract.limit(1)
    .where('driverId', driverId)
    .get(1)
  return response.getData()
}

// TerminateRentalContract
export const terminateRentalContractFx = domain.createEffect<
  { rentalContractId: UniqueId; reasons: string[] },
  void,
  AxiosErrorType
>({
  async handler({ rentalContractId, reasons }) {
    const rentalContract = new RentalContract(undefined, rentalContractId)
    await rentalContract.terminate(reasons)
  },
})

export const goToRentalContractNew = domain.createEvent()
export const goToRentalContractNewFx = domain.createEffect({
  handler() {
    pushFx('rental-contracts/new')
  },
})

sample({
  clock: goToRentalContractNew,
  target: goToRentalContractNewFx,
})

sample({
  clock: terminateRentalContractFx.doneData,
  fn() {
    return {
      message: 'Договор расторгнут',
      variant: 'warning' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: terminateRentalContractFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// SuspendRentalContract
export const suspendRentalContractFx = domain.createEffect<
  { rentalContractId: UniqueId; reason: CarAtWorkSubStatusEnum },
  void,
  AxiosErrorType
>({
  async handler({ rentalContractId, reason }) {
    const rentalContract = new RentalContract(undefined, rentalContractId)
    await rentalContract.suspend(reason)
  },
})

sample({
  clock: suspendRentalContractFx.doneData,
  fn() {
    return {
      message: 'Договор успешно приостановлен!',
      variant: 'warning' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: suspendRentalContractFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// ContinueRentalContract
export const continueRentalContractFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(rentalContractId) {
    const rentalContract = new RentalContract(undefined, rentalContractId)
    await rentalContract.continue()
  },
})

sample({
  clock: continueRentalContractFx.doneData,
  fn() {
    return {
      message: 'Договор успешно возобновлен!',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: continueRentalContractFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// SigningRentalContract
export const signingRentalContractFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(rentalContractId) {
    const rentalContract = new RentalContract(undefined, rentalContractId)
    await rentalContract.activate()
  },
})

sample({
  clock: signingRentalContractFx.doneData,
  fn() {
    return {
      message: 'Договор успешно подписан!',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: signingRentalContractFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// CanceledRentalContract
export const canceledRentalContractFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(rentalContractId) {
    const rentalContract = new RentalContract(undefined, rentalContractId)
    await rentalContract.signingCancel()
  },
})

sample({
  clock: canceledRentalContractFx.doneData,
  fn() {
    return {
      message: 'Договор аннулирован!',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: canceledRentalContractFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
