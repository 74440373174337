import { CircularProgress, Stack } from '@mui/joy'
import { format, parseISO } from 'date-fns'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from '~/widgets/AuditsModal'
import { RentalContractPaymentSchedule } from '~/widgets/Driver/RentalContractControls'
import {
  EditRentalContract,
  editRentalContractModel,
} from 'src/features/RentalContracts/EditRentalContract'
import { driverModel } from '~/entities/Driver'
import {
  rentalContractModel,
  Status,
  Print,
  useRentalContractMachineInterpreter,
  RentalContractMachineContext,
} from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { datetime } from '~/shared/config'
import { Breadcrumbs } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { Actions } from './ui/Actions'

export const ViewRentalContractPage = () => {
  const params = useParams<{ rentalContractId: string; driverId: string }>()
  const rentalContractId = params.rentalContractId as string
  const driverId = params.driverId as string

  const { canAuditsView } = usePermissions()

  const isLoading = useStore(rentalContractModel.requestFx.pending)

  const driverFullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )
  const rentalContract = useStore(editRentalContractModel.$rentalContract)
  const rentalContractDriverId = rentalContract?.getDriverId()
  const rentalFilePath = rentalContract?.getFilepath()
  const status = rentalContract?.getStatus()
  const number = rentalContract?.getNumber()
  const createdAt = rentalContract?.getCreatedAt()

  const interpreter = useRentalContractMachineInterpreter(status)
  const rentalContractMachineCtxValue = useMemo(
    () => ({ interpreter }),
    [interpreter],
  )

  const title = `${number} от ${
    createdAt ? format(parseISO(createdAt), datetime.uiDateFormat) : ''
  }`

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Водители',
        link: '/drivers',
      },
      {
        title: driverFullName || 'Водитель ...',
        link: `/drivers/${driverId}/details`,
      },
      {
        title: 'Договоры',
        link: `/drivers/${driverId}/rental-contracts`,
      },
      { title },
    ],
    [driverFullName, driverId, title],
  )

  const rentalContractError =
    rentalContractModel.useRentalContractError(rentalContractId)

  if (rentalContractError) {
    return <NotFound message='Не удалось загрузить данные договора' />
  }

  if (
    !isLoading &&
    rentalContract &&
    rentalContract.getApiId() === rentalContractId &&
    driverId !== rentalContractDriverId
  ) {
    return (
      <NotFound
        message={`Загруженный договор [${rentalContractId}] не соответствует текущему водителю [${driverId}]`}
      />
    )
  }

  return (
    <RentalContractMachineContext.Provider
      value={rentalContractMachineCtxValue}
    >
      <PageContainer title='Карточка договора'>
        <Container isHeader>
          <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />

          <Status isLoading={isLoading} />

          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-end'
            spacing={2}
          >
            <PageTitle loading={isLoading}>{title}</PageTitle>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <Stack direction='row' spacing={2}>
                {canAuditsView && (
                  <AuditsModal auditableId={rentalContractId} />
                )}
                {rentalFilePath && <Print filepath={rentalFilePath} />}

                <Actions rentalContractId={rentalContractId} />

                <RentalContractPaymentSchedule
                  rentalContractId={rentalContractId}
                  isIcon
                />
              </Stack>
            )}
          </Stack>
        </Container>
        <EditRentalContract rentalContractId={rentalContractId} />
      </PageContainer>
    </RentalContractMachineContext.Provider>
  )
}
