import { AxiosResponse, AxiosError } from 'axios'
import last from 'lodash/last'
import * as z from 'zod'

import { CarEquipment } from '~/shared/api/carEquipment'
import { WorkRule } from '~/shared/api/workRule'
import {
  CAR_VIN_LENGTH,
  FILTER_WITHOUT_EMPTY_ENTITIES,
  Option,
} from '~/shared/config/constants'
import {
  TransmissionEnum,
  FuelTypeEnum,
  CarStatusEnum,
  CarPropertyTypeEnum,
  CarDocumentType,
  CarUnderRepairSubStatusEnum,
  CarAtWorkSubStatusEnum,
} from '~/shared/config/enums'
import { mapErrorBlobToErrorType } from '~/shared/lib/mapMessageErrors'
import {
  dateSchema,
  enumOptionSchema,
  priceRequiredScheme,
  uuidOptionSchema,
  yearSchema,
} from '~/shared/lib/schemas'
import { isString } from '~/shared/lib/utils'
import { AggregatorAccount } from './aggregator/aggregatorAccount'
import { CarModel } from './carModel'
import { CarRental } from './carRental'
import { Color } from './color'
import { ApiModel, ToManyRelation, ToOneRelation } from './core'
import { DiagnosticCard } from './diagnosticCard'
import { KaskoDocument } from './kaskoDocument'
import { Location } from './location'
import { OsagoDocument } from './osagoDocument'
import { PtsDocument } from './ptsDocument'
import { Region } from './region'
import { StatusTransition } from './statusTransition'
import { StsDocument } from './stsDocument'
import { Subdivision } from './subdivision'
import { TaxiLicense } from './taxiLicense'
import { TelematicAccount } from './telematicAccount'
import { VehicleCategory } from './vehicleCategory'

const attributesSchema = z.object({
  vin: z
    .string()
    .min(1, 'Обязательное поле')
    .length(
      CAR_VIN_LENGTH,
      `VIN номер должен содержать ${CAR_VIN_LENGTH} символов`,
    )
    .regex(
      /^[\dA-HJ-NPR-Z]{13}\d{4}$/,
      `VIN имеет неправильный формат, ожидаются ${CAR_VIN_LENGTH} символов из списка: 0123456789ABCDEFGHJKLMNPRSTUVWXYZ`,
    ),
  plateNumber: z.string().optional().nullable(),
  transmission: enumOptionSchema(TransmissionEnum),
  engineNumber: z.string().optional().nullable(),
  engineSize: z.string().optional().nullable(),
  enginePower: z
    .string()
    .or(z.number())
    .transform((value) => {
      if (!value) return null
      return value && isString(value)
        ? parseFloat(value.replace(' л.с.', '').replace(',', '.'))
        : value
    })
    .optional()
    .nullable(),
  fuelType: enumOptionSchema(FuelTypeEnum),
  manufactureYear: yearSchema,
  bodyNumber: z.string().optional().nullable(),
  startExploitationDate: dateSchema.optional().nullable(),
  status: z.nativeEnum(CarStatusEnum).optional(),
  estimatedCost: priceRequiredScheme,
  isLeasingFullyPaid: z.boolean().default(false),
  isPromotion: z.boolean().nullable().default(false),
  isPreTotaled: z.boolean().nullable().default(false),
  plannedDateEndRepair: dateSchema,
  repairComment: z.string(),
  propertyType: enumOptionSchema(CarPropertyTypeEnum).optional().nullable(),
})
const relationsSchema = z.object({
  modelId: uuidOptionSchema,
  subdivisionId: uuidOptionSchema,
  colorId: uuidOptionSchema,
  vehicleCategoryId: uuidOptionSchema,
  carRentalId: uuidOptionSchema,
  regionId: uuidOptionSchema,
  locationId: uuidOptionSchema,
  aggregatorAccountOption: uuidOptionSchema,
  telematicAccountOption: uuidOptionSchema.optional().nullable(),
  workRuleId: uuidOptionSchema,
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type CarAttributes = z.infer<typeof attributesSchema>
export type CarOptionForRentalContract = Option & {
  model?: string
  brand?: string
  rental?: string
  workRule?: string
  workRuleId?: UniqueId
}

export type CarOptionForTransaction = {
  id: UniqueId
  label: string
  aggregatorAccountsOptions?: Option[]
  aggregatorAccount?: string
}

export type CarOptionForAppeal = {
  id: UniqueId
  label: string
  subdivisionOption?: Option | undefined
}

export type CarStatusUpdateType = {
  status: CarStatusEnum
  comment?: string
  subStatus?: CarUnderRepairSubStatusEnum
}

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  getTransmission(): TransmissionEnum {
    return this.getAttribute('transmission')
  }
  getFuelType(): FuelTypeEnum {
    return this.getAttribute('fuelType')
  }
  getPropertyType(): CarPropertyTypeEnum {
    return this.getAttribute('propertyType')
  }
  getisPromotion(): boolean {
    return this.getAttribute('isPromotion')
  }
  getPlannedDateEndRepair() {
    return this.getAttribute('plannedDateEndRepair')
  }
  getRepairComment() {
    return this.getAttribute('repairComment')
  }

  static getStatusColor(status?: CarStatusEnum) {
    switch (status) {
      case CarStatusEnum.CREATED:
        return 'gray'
      case CarStatusEnum.TRANSFER:
        return 'brand'
      case CarStatusEnum.AT_WORK:
        return 'green'
      case CarStatusEnum.UNDER_REPAIR:
        return 'brand'
      case CarStatusEnum.FREE:
        return 'purple'
      case CarStatusEnum.SUB_RENT:
        return 'gray'
      case CarStatusEnum.BOOKED_FOR_RENT:
        return 'yellow'
      case CarStatusEnum.FOR_SALE:
        return 'blue'
      case CarStatusEnum.TOTALED:
        return 'outlinedRed'
      case CarStatusEnum.ILLIQUID:
        return 'red'
      case CarStatusEnum.SOLD:
        return 'gray'
      case CarStatusEnum.INTERNAL_USING:
        return 'outlinedGreen'
      default:
        return 'gray'
    }
  }

  color(): ToOneRelation<Color, this> {
    return this.hasOne(Color)
  }
  getColor(): Color {
    return this.getRelation('color')
  }
  setColor(id: UniqueId) {
    const color = new Color()
    color.setApiId(id)
    this.setRelation('color', color)
  }

  getCarModelTitleAttribute(): string {
    return this.getAttribute('model')?.title
  }
  carModel(): ToOneRelation<CarModel, this> {
    return this.hasOne(CarModel)
  }
  getCarModel(): CarModel {
    return this.getRelation('carModel')
  }
  setCarModel(id: UniqueId) {
    const carModel = new CarModel()
    carModel.setApiId(id)
    this.setRelation('carModel', carModel)
  }

  subdivision(): ToOneRelation<Subdivision, this> {
    return this.hasOne(Subdivision)
  }
  getSubdivision(): Subdivision {
    return this.getRelation('subdivision')
  }
  setSubdivision(id: UniqueId) {
    const subdivision = new Subdivision()
    subdivision.setApiId(id)
    this.setRelation('subdivision', subdivision)
  }

  vehicleCategory(): ToOneRelation<VehicleCategory, this> {
    return this.hasOne(VehicleCategory)
  }
  getVehicleCategory(): VehicleCategory {
    return this.getRelation('vehicleCategory')
  }
  setVehicleCategory(id: UniqueId) {
    const vehicleCategory = new VehicleCategory()
    vehicleCategory.setApiId(id)
    this.setRelation('vehicleCategory', vehicleCategory)
  }

  getBrandAndModelTitle() {
    const model = this.getCarModel()
    const brand = model?.getCarBrand()
    return [brand?.getTitle(), model?.getTitle()].filter(Boolean).join(' ')
  }

  getAttributeBrandTitle() {
    return this.getAttribute('brand')?.title
  }

  getAttributeModelTitle() {
    return this.getAttribute('model')?.title
  }

  getStatus(): CarStatusEnum {
    return this.getAttribute('status')
  }
  getStatusLabel(): CarStatusEnum {
    return this.getAttribute('statusLabel')
  }

  getSubStatus(): CarUnderRepairSubStatusEnum | CarAtWorkSubStatusEnum {
    return this.getAttribute('subStatus')
  }

  getVin(): string {
    return this.getAttribute('vin')
  }

  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }
  getEstimatedCost(): number {
    return this.getAttribute('estimatedCost')
  }

  carRental(): ToOneRelation<CarRental, this> {
    return this.hasOne(CarRental)
  }
  getCarRental(): CarRental {
    return this.getRelation('carRental')
  }
  setCarRental(id: UniqueId) {
    const carRental = new CarRental()
    carRental.setApiId(id)
    this.setRelation('carRental', carRental)
  }

  region(): ToOneRelation<Region, this> {
    return this.hasOne(Region)
  }
  getRegion(): Region {
    return this.getRelation('region')
  }
  setRegion(id: UniqueId) {
    const region = new Region()
    region.setApiId(id)
    this.setRelation('region', region)
  }

  location(): ToOneRelation<Location, this> {
    return this.hasOne(Location)
  }
  getLocation(): Location {
    return this.getRelation('location')
  }
  setLocation(id: UniqueId) {
    const location = new Location()
    location.setApiId(id)
    this.setRelation('location', location)
  }

  workRule(): ToOneRelation<WorkRule, this> {
    return this.hasOne(WorkRule)
  }
  getWorkRule(): WorkRule {
    return this.getRelation('workRule')
  }

  aggregatorAccount(): ToOneRelation<AggregatorAccount, this> {
    return this.hasOne(AggregatorAccount)
  }
  getAggregatorAccount(): AggregatorAccount {
    return this.getRelation('aggregatorAccount')
  }
  setAggregatorAccount(id: UniqueId) {
    const aggregatorAccount = new AggregatorAccount()
    aggregatorAccount.setApiId(id as string)
    this.setRelation('aggregatorAccount', aggregatorAccount)
  }

  aggregatorAccounts(): ToManyRelation<AggregatorAccount, this> {
    return this.hasMany(AggregatorAccount)
  }
  getAggregatorAccounts(): AggregatorAccount[] {
    return this.getRelation('aggregatorAccounts')
  }
  setAggregatorAccounts(ids: UniqueId[]) {
    const aggregatorAccounts = ids.map((id) => {
      const aggregatorAccount = new AggregatorAccount()
      aggregatorAccount.setApiId(id as string)
      return aggregatorAccount
    })
    this.setRelation('aggregatorAccounts', aggregatorAccounts)
  }

  telematicAccount(): ToOneRelation<TelematicAccount, this> {
    return this.hasOne(TelematicAccount)
  }
  getTelematicAccount(): TelematicAccount {
    return this.getRelation('telematicAccount')
  }
  setTelematicAccount(id: UniqueId) {
    const telematicAccount = new TelematicAccount()
    telematicAccount.setApiId(id)
    this.setRelation('telematicAccount', telematicAccount)
  }
  async detachTelematicAccount() {
    const url = `${Car.getJsonApiUrl()}/${this.getApiId()}`
    const client = Car.httpClient.getImplementingClient()
    await client.patch(url, {
      data: {
        type: 'cars',
        relationships: {
          telematicAccount: {
            data: null,
          },
        },
        id: this.getApiId(),
      },
    })
  }
  getOptionForRentalContract(): CarOptionForRentalContract {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
      model: this.getCarModel()?.getTitle(),
      brand: this.getCarModel()?.getCarBrand()?.getTitle(),
      rental: this.getCarRental()?.getName(),
      workRule: this.getWorkRule()?.getTitle(),
      workRuleId: this.getWorkRule()?.getApiId(),
    }
  }

  getModel(): string {
    return this.getAttribute('model')?.title
  }
  getAttributeBrand() {
    return this.getAttribute('brand')?.title
  }

  diagnosticCards(): ToManyRelation<DiagnosticCard, this> {
    return this.hasMany(DiagnosticCard, 'diagnosticCards')
  }
  getDiagnosticCards(): DiagnosticCard[] {
    return this.getRelation('diagnosticCards')
  }

  kaskoDocuments(): ToManyRelation<KaskoDocument, this> {
    return this.hasMany(KaskoDocument, 'kaskoDocuments')
  }
  getKaskoDocuments(): KaskoDocument[] {
    return this.getRelation('kaskoDocuments')
  }

  osagoDocuments(): ToManyRelation<OsagoDocument, this> {
    return this.hasMany(OsagoDocument, 'osagoDocuments')
  }
  getOsagoDocuments(): OsagoDocument[] {
    return this.getRelation('osagoDocuments')
  }

  ptsDocuments(): ToManyRelation<PtsDocument, this> {
    return this.hasMany(PtsDocument, 'ptsDocuments')
  }
  getPtsDocuments(): PtsDocument[] {
    return this.getRelation('ptsDocuments')
  }

  stsDocuments(): ToManyRelation<StsDocument, this> {
    return this.hasMany(StsDocument, 'stsDocuments')
  }
  getStsDocuments(): StsDocument[] {
    return this.getRelation('stsDocuments')
  }

  taxiLicenses(): ToManyRelation<TaxiLicense, this> {
    return this.hasMany(TaxiLicense, 'taxiLicenses')
  }
  getTaxiLicenses(): TaxiLicense[] {
    return this.getRelation('taxiLicenses')
  }

  static async saveDocumentFile(
    file: File,
    documentId: UniqueId,
    type: CarDocumentType,
    carId: UniqueId,
  ) {
    const url = `${Car.getJsonApiUrl()}/${carId}/actions/save-document-file`

    const data = new FormData()
    data.append('file', file)
    data.append('documentType', type)
    data.append('documentId', documentId)
    const client = Car.httpClient.getImplementingClient()

    return await client.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async deleteDocumentFile(fileId: UniqueId, carId: UniqueId) {
    const url = `${Car.getJsonApiUrl()}/${carId}/actions/delete-document-file`
    const client = Car.httpClient.getImplementingClient()
    return await client.delete(url, { params: { fileId } })
  }

  getOptionForTransaction(): CarOptionForTransaction {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
      aggregatorAccount: this.getAggregatorAccount()?.getTitle(),
    }
  }

  getOptionForAppeal(): CarOptionForAppeal {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
      subdivisionOption: this.getSubdivision()?.getOption(),
    }
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await Car.where(
      'plateNumber',
      search || FILTER_WITHOUT_EMPTY_ENTITIES,
    ).get(1)
    return response.getData().map((o) => o.getOption())
  }

  static getExportCarDocumentsStatusReportLink() {
    return `${Car.getJsonApiUrl()}/actions/export-car-documents-status-report`
  }

  static getMassImportTemplate() {
    return `${Car.getJsonApiUrl()}/actions/get-mass-import-template`
  }

  static async massImport(
    file: File,
  ): Promise<AxiosResponse<Blob | undefined>> {
    const url = `${Car.getJsonApiUrl()}/actions/mass-import`

    const data = new FormData()
    data.append('carImport', file)
    const client = Car.httpClient.getImplementingClient()

    try {
      return await client.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      })
    } catch (e) {
      throw await mapErrorBlobToErrorType(e as AxiosError<Blob>)
    }
  }

  static async updateStatus(values: CarStatusUpdateType, carId: UniqueId) {
    const url = `${Car.getJsonApiUrl()}/${carId}/actions/update-status`
    return await Car.effectiveHttpClient.post(url, values)
  }

  statusTransitions(): ToManyRelation<StatusTransition, this> {
    return this.hasMany(StatusTransition)
  }

  getStatusTransitions(): StatusTransition[] {
    return this.getRelation('statusTransitions') ?? []
  }

  getLastStatusTransition(): StatusTransition {
    return last(this.getStatusTransitions()) as StatusTransition
  }

  equipments(): ToManyRelation<CarEquipment, this> {
    return this.hasMany(CarEquipment)
  }
  getEquipments(): CarEquipment[] {
    return this.getRelation('equipments')
  }

  static async printFreighterCard(id: UniqueId): Promise<Blob> {
    const url = `${Car.getJsonApiUrl()}/${id}/actions/print-freighter-card`
    const client = Car.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }
}
