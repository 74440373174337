import { createDomain, sample, combine } from 'effector'
import { createGate } from 'effector-react'
import { omit, uniqBy } from 'lodash'
import { debounce, spread } from 'patronum'
import { mappedFormDataToFiltersData } from 'src/features/Drivers/AddDriver'
import { FormValues } from '~/entities/Driver'
import { Driver } from '~/shared/api'
import { sanitizePhoneNumber } from '~/shared/lib/utils'

type Filters = Partial<FormValues>

export const domain = createDomain('features.driver.previewList')
export const Gate = createGate()

export const filtersChanged = domain.createEvent<Filters>()
const debouncedFiltersChanged = debounce({
  source: filtersChanged,
  timeout: 400,
})

type RestFilters = Omit<Filters, 'phone' | 'data'>
const $rest = domain.createStore<RestFilters>({})
const $phone = domain.createStore<string>('')
sample({
  clock: debouncedFiltersChanged,
  fn: ({ phone = '', ...rest }) => ({
    phone: sanitizePhoneNumber(phone ?? ''),
    rest: mappedFormDataToFiltersData(omit(rest, 'data')),
  }),
  target: spread({
    targets: {
      phone: $phone,
      rest: $rest,
    },
  }),
})

async function requestByFilters(filters: Filters) {
  let builder = Driver.orderBy('lastName')
  Object.entries(filters)
    .filter(([, value]) => !!value)
    .forEach(([attr, value]) => {
      builder = builder.where(attr, `${value}`)
    })
  const response = await builder.get(1)
  return response.getData()
}
export const requestByRestFx = domain.createEffect<RestFilters, Driver[]>({
  async handler(rest) {
    return requestByFilters(rest)
  },
})
export const requestByPhoneFx = domain.createEffect<string, Driver[]>({
  async handler(phone) {
    return requestByFilters({ phone })
  },
})

sample({
  clock: $phone,
  filter: (phone) => phone.length > 1,
  target: requestByPhoneFx,
})
sample({
  clock: $rest,
  filter: (rest) => Object.entries(rest).length > 0,
  target: requestByRestFx,
})

const $driversByRest = domain
  .createStore<Driver[]>([])
  .on(requestByRestFx.doneData, (_, drivers) => drivers)
  .on([Gate.open, $rest], () => [])
const $driversByPhone = domain
  .createStore<Driver[]>([])
  .on(requestByPhoneFx.doneData, (_, drivers) => drivers)
  .on([Gate.open, $phone], () => [])

export const $drivers = combine(
  $driversByPhone,
  $driversByRest,
  (byPhone, byRest) =>
    uniqBy([...byPhone, ...byRest], (driver) => driver.getApiId()),
)
