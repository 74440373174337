import { useInterpret, useSelector } from '@xstate/react'
import { createContext, useContext, useLayoutEffect } from 'react'
import { createMachine, InterpreterFrom, StateFrom } from 'xstate'
import { RentalContractAttributes } from '~/shared/api'
import { RentalContractStatusEnum } from '~/shared/config/enums'

export const rentalContractMachine = createMachine({
  id: 'RentalContract',
  initial: RentalContractStatusEnum.SIGNING,
  states: {
    [RentalContractStatusEnum.SIGNING]: {
      description: 'На подписании',
      on: {
        signing: {
          target: RentalContractStatusEnum.ACTIVE,
        },
        canceled: {
          target: RentalContractStatusEnum.SIGNING_CANCELLED,
        },
        booked_until_visible: {
          target: RentalContractStatusEnum.SIGNING,
        },
      },
      meta: {
        label: 'На подписании',
        color: 'brand',
      },
    },
    [RentalContractStatusEnum.ACTIVE]: {
      description: 'Действующий',
      on: {
        suspend: {
          target: RentalContractStatusEnum.SUSPENDED,
        },
        terminate: {
          target: RentalContractStatusEnum.TERMINATED,
        },
        form_edit: {
          target: RentalContractStatusEnum.ACTIVE,
        },
      },
      meta: {
        label: 'Действующий',
        color: 'green',
      },
    },
    [RentalContractStatusEnum.SUSPENDED]: {
      description: 'Приостановлен',
      on: {
        continue: {
          target: RentalContractStatusEnum.ACTIVE,
        },
        terminate: {
          target: RentalContractStatusEnum.TERMINATED,
        },
      },
      meta: {
        label: 'Приостановлен',
        color: 'yellow',
      },
    },
    [RentalContractStatusEnum.SIGNING_CANCELLED]: {
      description: 'Аннулирован',
      meta: {
        label: 'Аннулирован',
        color: 'outlinedRed',
      },
    },
    [RentalContractStatusEnum.TERMINATED]: {
      description: 'Расторгнут',
      meta: {
        label: 'Расторгнут',
        color: 'red',
      },
    },
  },
  schema: {
    context: {},
    events: {} as
      | { type: 'signing' }
      | { type: 'canceled' }
      | { type: 'suspend' }
      | { type: 'terminate' }
      | { type: 'continue' }
      | { type: 'form_edit' }
      | { type: 'booked_until_visible' },
  },
  context: {},
  predictableActionArguments: true,
  preserveActionOrder: true,
})

export const useRentalContractMachineInterpreter = (
  rentalContractStatusEnum?: RentalContractAttributes['status'],
) => {
  const interpreter = useInterpret(rentalContractMachine)
  useLayoutEffect(() => {
    if (rentalContractStatusEnum) {
      interpreter.start(rentalContractStatusEnum)
    }
    return () => {
      interpreter.stop()
    }
  }, [interpreter, rentalContractStatusEnum])

  return interpreter
}

type MachineContextValue = {
  interpreter: InterpreterFrom<typeof rentalContractMachine>
}
type MachineState = StateFrom<typeof rentalContractMachine>
export const RentalContractMachineContext = createContext<MachineContextValue>(
  {} as MachineContextValue,
)

type Meta = { label: string; color: string }
export const getMeta = (status?: RentalContractStatusEnum): Meta => {
  return status
    ? rentalContractMachine.getStateNode(status)?.meta
    : { label: '', color: '' }
}

const getCan = (state: MachineState) => {
  return {
    signing: state.can('signing'),
    canceled: state.can('canceled'),
    suspend: state.can('suspend'),
    terminate: state.can('terminate'),
    continue: state.can('continue'),
    formEdit: state.can('form_edit'),
    bookedUntilVisible: state.can('booked_until_visible'),
  }
}

export const useRentalContractMachineContext = () => {
  const machineCtx = useContext(RentalContractMachineContext)

  const currentStatus = useSelector(
    machineCtx.interpreter,
    (state) => state.value,
  ) as RentalContractStatusEnum

  const meta = getMeta(currentStatus)
  const canStatus = useSelector(machineCtx.interpreter, (state) =>
    getCan(state),
  )

  return { canStatus, meta }
}

export const getCanRentalContractMachine = (
  status?: RentalContractStatusEnum,
) => {
  if (!status) return null

  const state = rentalContractMachine.resolveState({
    ...rentalContractMachine.initialState,
    value: status,
  })

  const canStatus = getCan(state)

  return canStatus
}
