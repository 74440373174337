import {
  RentalContractCanceled,
  RentalContractContinue,
  RentalContractSigning,
  RentalContractSuspend,
  RentalContractTerminate,
} from '~/widgets/Driver/RentalContractControls'
import { useRentalContractMachineContext } from '~/entities/RentalContract'

type ActionsProps = {
  rentalContractId: UniqueId
}

export function Actions({ rentalContractId }: ActionsProps) {
  const { canStatus } = useRentalContractMachineContext()

  return (
    <>
      {canStatus.signing && (
        <RentalContractSigning isButton rentalContractId={rentalContractId} />
      )}
      {canStatus.canceled && (
        <RentalContractCanceled isButton rentalContractId={rentalContractId} />
      )}
      {canStatus.terminate && (
        <RentalContractTerminate
          rentalContractId={rentalContractId}
          goBackOnTermination
          isButton
        />
      )}
      {canStatus.suspend && (
        <RentalContractSuspend isIcon rentalContractId={rentalContractId} />
      )}
      {canStatus.continue && (
        <RentalContractContinue isIcon rentalContractId={rentalContractId} />
      )}
    </>
  )
}
