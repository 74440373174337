import { CircularProgress } from '@mui/joy'
import { Stack } from '@mui/material'
import { AuditsModal } from 'src/widgets/AuditsModal'
import { RentalContractCreated } from '~/widgets/Driver/RentalContractControls'
import { usePermissions } from '~/entities/viewer'
import { ButtonDebtExtra } from '../ButtonDebtExtra'

type ActionButtonsProps = {
  driverId: string
  loading: boolean
}
export function ActionButtons({ driverId, loading }: ActionButtonsProps) {
  const { canAuditsView } = usePermissions()

  if (loading) return <CircularProgress size='sm' />

  return (
    <Stack spacing={2} direction='row' alignItems='center'>
      {canAuditsView && <AuditsModal auditableId={driverId} />}
      <RentalContractCreated />

      <ButtonDebtExtra />
    </Stack>
  )
}
