import { styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'
import { DatePickerPopper } from '../ui/styled'

export const DateTimePickerPopper = styled(DatePickerPopper)`
  .react-datepicker__time-container {
    .react-datepicker__time-list-item--selected {
      background-color: ${myPalette.brand['700']} !important;
      color: ${myPalette.white.main};
    }

    .react-datepicker__time-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;

      &:hover {
        background-color: ${myPalette.brand['400']} !important;
        color: ${myPalette.white.main};
      }
    }
  }
`
