import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { rentalContractModel } from '~/entities/RentalContract'
import { FormValues } from '~/entities/RentalContract/ui/Form'
import { RentalContract } from '~/shared/api'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ rentalContractId: UniqueId }>()
export const domain = createDomain('features.rentalContract.view')

export const $rentalContractId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { rentalContractId }) => rentalContractId)

export const update = domain.createEvent<FormValues>()
export const updateFx = attach({
  effect: rentalContractModel.saveFx,
  mapParams: ({
    formValues,
    rentalContractId,
  }: {
    formValues: FormValues
    rentalContractId: UniqueId
  }) => {
    const { workSchedule, dayOff, rentalServicesOptions } = formValues

    const rentalContract = new RentalContract(
      {
        ...(isString(workSchedule) && { workSchedule }),
        ...(isString(dayOff) && { dayOff }),
      },
      rentalContractId,
    )

    if (Array.isArray(rentalServicesOptions)) {
      rentalContract.setRentalServices(rentalServicesOptions.filter(isString))
    }

    return rentalContract
  },
})

sample({
  clock: update,
  source: $rentalContractId,
  filter: isString,
  fn(rentalContractId, formValues) {
    return {
      rentalContractId,
      formValues,
    }
  },
  target: updateFx,
})

sample({
  clock: updateFx.doneData,
  fn() {
    return {
      message: 'Договор успешно отредактирован',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: updateFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $rentalContract = combine(
  $rentalContractId,
  rentalContractModel.$rentalContractCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $rentalContractId,
  filter: isString,
  target: rentalContractModel.requestFx,
})

sample({
  clock: [
    rentalContractModel.signingRentalContractFx.doneData,
    rentalContractModel.canceledRentalContractFx.doneData,
    rentalContractModel.terminateRentalContractFx.doneData,
    rentalContractModel.suspendRentalContractFx.doneData,
    rentalContractModel.continueRentalContractFx.doneData,
  ],
  source: $rentalContractId,
  filter: isString,
  target: rentalContractModel.unseenRequestFx,
})
