import {
  Alert as JoyAlert,
  AlertProps as JoyAlertProps,
  Skeleton,
} from '@mui/joy'
import { ReactNode, useMemo } from 'react'
import { ErrorIcon, InfoIcon, WarningIcon } from '~/shared/ui/Icons'
import { myPalette } from '../../lib/theme'

type AlertProps = {
  variant: 'info' | 'warning' | 'error'
  children: ReactNode | string
  isLoading?: boolean
} & Partial<Omit<JoyAlertProps, 'variant' | 'children'>>

export function Alert({ variant, children, isLoading, ...props }: AlertProps) {
  const option = useMemo(() => {
    switch (variant) {
      case 'info':
        return {
          icon: <InfoIcon />,
          color: myPalette.brand['700'],
        }
      case 'warning':
        return {
          icon: <WarningIcon />,
          color: myPalette.yellow['600'],
        }
      case 'error':
        return {
          icon: <ErrorIcon />,
          color: myPalette.red['700'],
        }
    }
  }, [variant])

  return (
    <JoyAlert
      {...props}
      sx={{
        borderRadius: '100px',
        background: myPalette.gray['100'],
        color: option.color,
        ...props?.sx,
      }}
      startDecorator={option.icon}
    >
      {isLoading ? (
        <Skeleton
          sx={{
            height: '48px',
            position: 'relative',
            borderRadius: '24px',
          }}
        />
      ) : (
        <>{children}</>
      )}
    </JoyAlert>
  )
}
