const white = {
  main: '#FFFFFF',
}

const red = {
  '100': '#FEE4E2',
  '400': '#F97066',
  '500': '#b42318d1',
  '600': '#D92D20',
  '700': '#b42318',
}

const green = {
  '100': '#DCFAE6',
  '400': '#47CD89',
  '600': '#079455',
  '700': '#067647',
}

const purple = {
  '100': '#EBE9FE',
  '600': '#6938EF',
}

const blue = {
  '100': '#D1E9FF',
  '600': '#1570EF',
}

const brand = {
  '100': 'rgb(218, 228, 255)',
  '300': '#95A9FF',
  '400': '#6C7AFF',
  '500': 'rgb(235, 240, 255)',
  '600': 'rgb(56, 42, 255)',
  '700': '#3525E5',
  '800': 'rgb(56, 42, 205)',
  '900': '#241f90',
}

const gray = {
  '50': '#F9FAFB',
  '100': '#F2F4F7',
  '200': '#EAECF0',
  '300': 'rgb(208, 213, 221)',
  '400': '#98A2B3',
  '500': 'rgb(102, 112, 133)',
  '600': '#475467',
  '700': '#344054',
  '800': '#182230',
  '900': '#101828',
}

const yellow = {
  '100': '#FEF0C7',
  '400': '#FAC515',
  '600': '#DC6803',
  '700': '#A15C07',
}

export const myPalette = {
  blue,
  brand,
  white,
  gray,
  red,
  purple,
  green,
  yellow,
}
