import { useSelector } from '@xstate/react'
import { useStore } from 'effector-react'
import { useCallback, useContext } from 'react'
import { DriverMachineContext, driverModel } from 'src/entities/Driver'
import { usePermissions } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { TravelSheetCreateModal } from '../TravelSheetCreateModal'
import { TravelSheetWarningCannotCreateModal } from '../TravelSheetWarningCannotCreateModal'

export const TravelSheetCreate = () => {
  const { canTravelSheetsCreate } = usePermissions()
  const {
    isModalShow: isTravelSheetCreateModalShow,
    openModal: openTravelSheetCreateModal,
    closeModal: closeTravelSheetCreateModal,
  } = useModal()
  const {
    isModalShow: isTravelSheetWarningCreateModalShow,
    openModal: openTravelSheetWarningCreateModal,
    closeModal: closeTravelSheetWarningCreateModal,
  } = useModal()

  const isLoading = useStore(driverModel.requestDriverFx.pending)
  const machineCtx = useContext(DriverMachineContext)
  const can = useSelector(machineCtx.interpreter, (state) => ({
    create_travel_sheet: state.can('create_travel_sheet'),
  }))

  const handleOpenModal = useCallback(() => {
    can?.create_travel_sheet
      ? openTravelSheetCreateModal()
      : openTravelSheetWarningCreateModal()
  }, [
    can?.create_travel_sheet,
    openTravelSheetCreateModal,
    openTravelSheetWarningCreateModal,
  ])

  return (
    <>
      <Button
        show={canTravelSheetsCreate}
        onClick={handleOpenModal}
        startDecorator={<PlusIcon />}
        variant='brand'
        loading={isLoading}
      >
        Сформировать
      </Button>
      <TravelSheetCreateModal
        isShow={isTravelSheetCreateModalShow}
        onClose={closeTravelSheetCreateModal}
      />
      <TravelSheetWarningCannotCreateModal
        isShow={isTravelSheetWarningCreateModalShow}
        onClose={closeTravelSheetWarningCreateModal}
      />
    </>
  )
}
