import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { rentalContractModel } from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { ClockRefreshGreenIcon, ClockRefreshWhiteIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { ContinueModal } from './ContinueModal'

type RentalContractContinueProps = {
  rentalContractId: UniqueId
  isButton?: boolean
  isIcon?: boolean
}

export const RentalContractContinue = ({
  rentalContractId,
  isButton,
  isIcon,
}: RentalContractContinueProps) => {
  const { canRentalContractsSuspend } = usePermissions()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { isModalShow, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const handleContinue = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await rentalContractModel.continueRentalContractFx(rentalContractId)
      await queryClient.invalidateQueries({
        queryKey: ['rental-contracts-list'],
      })
      closeModal()
    } finally {
      setIsSubmitting(false)
    }
  }, [closeModal, queryClient, rentalContractId])

  if (!canRentalContractsSuspend) return null

  return (
    <>
      <ContinueModal
        onOk={handleContinue}
        onCancel={closeModal}
        isShow={isModalShow}
        isSubmitting={isSubmitting}
      />
      {isButton && (
        <Button
          variant='green'
          startDecorator={<ClockRefreshWhiteIcon />}
          onClick={openModal}
        >
          Возобновить договор
        </Button>
      )}
      {isIcon && (
        <IconButton
          tooltipProps={{ title: 'Возобновить договор' }}
          onClick={openModal}
        >
          <ClockRefreshGreenIcon />
        </IconButton>
      )}
    </>
  )
}
