import { useFormContext } from 'react-hook-form'
import { Location } from '~/shared/api'
import { AsyncAutocompleteInput } from '~/shared/ui/Form'

type Props = {
  showSkeleton: boolean | undefined
  disabled: boolean | undefined
}

export function LocationAsyncAutocompleteInput({
  showSkeleton,
  disabled,
}: Props) {
  const { watch } = useFormContext()

  const regionOption = watch('regionId')
  const regionId = regionOption?.id

  return (
    <AsyncAutocompleteInput
      label='Локация'
      name='locationId'
      placeholder='Выберите локацию'
      skeletonShow={showSkeleton}
      readOnly={disabled}
      fetchOptions={(name) => Location.fetchOptions(name, regionId)}
      queryKey={regionId ? [regionId] : []}
    />
  )
}
