import { createDomain, sample } from 'effector'
import { z } from 'zod'
import {
  AxiosErrorType,
  Car,
  CarOptionForRentalContract,
  RentalContract,
} from '~/shared/api'
import {
  FILTER_WITHOUT_EMPTY_ENTITIES,
  Option,
  OptionEnum,
} from '~/shared/config/constants'
import {
  CarStatusEnum,
  DayEnum,
  RentalContractWorkScheduleEnum,
} from '~/shared/config/enums'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('entities.rentalContract.form')

export const formSchema = RentalContract.schema.pick({
  carOption: true,
  firstDayIsFree: true,
  workSchedule: true,
  contractNumber: true,
  driverId: true,
  dayOff: true,
  rentalServicesOptions: true,
  bookedUntil: true,
})

export type FormValues = Omit<
  z.infer<typeof formSchema>,
  | 'carOption'
  | 'workSchedule'
  | 'dayOff'
  | 'rentalServicesOptions'
  | 'bookedUntil'
> & {
  carOption: UniqueId | CarOptionForRentalContract | null
  brand?: string
  model?: string
  rental?: string
  terminationDate?: string
  signedAt?: string | null
  responsibleManager?: string
  workSchedule:
    | OptionEnum<RentalContractWorkScheduleEnum>
    | RentalContractWorkScheduleEnum
    | null
  dayOff: OptionEnum<DayEnum> | DayEnum | null
  rentalServicesOptions: UniqueId[] | Option[]
  rentPerDay?: number
  workRule?: string
  bookedUntil: string
}

export const fetchCarOptions = async (search?: string) => {
  const builder = Car.where(
    'plateNumber',
    search || FILTER_WITHOUT_EMPTY_ENTITIES,
  )
    .with('carModel')
    .with('carModel.carBrand')
    .with('carRental')
    .with('workRule')
    .where('status', CarStatusEnum.FREE)

  const response = await builder.get(1)
  return response.getData().map((o) => o.getOptionForRentalContract())
}

export const calculateRentPerDay = domain.createEvent<{
  workRuleId?: UniqueId
  workSchedule?: RentalContractWorkScheduleEnum
  fn: (value?: number) => void
}>()

export const calculateRentPerDayFx = domain.createEffect<
  {
    workRuleId?: UniqueId
    workSchedule?: RentalContractWorkScheduleEnum
    fn: (value?: number) => void
  },
  void,
  AxiosErrorType
>({
  handler: async ({ workRuleId, workSchedule, fn }) => {
    if (!workRuleId || !workSchedule) {
      fn(undefined)
      return
    }
    const { rentPerDay } = await RentalContract.getRentPerDay(
      workRuleId,
      workSchedule,
    )
    fn(rentPerDay)
  },
})

sample({
  clock: calculateRentPerDay,
  target: calculateRentPerDayFx,
})

sample({
  clock: calculateRentPerDayFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
