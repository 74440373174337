import { useGate, useStore, useStoreMap } from 'effector-react'
import {
  rentalContractModel,
  Form,
  useRentalContractMachineContext,
} from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { CarOptionForRentalContract } from '~/shared/api'
import { daysEnumLabels } from '~/shared/config/constants'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { $rentalContract, Gate, update, updateFx } from './model'

type ViewRentalContractProps = {
  rentalContractId: string
  canActions?: boolean
  canEdit?: boolean
}

export function EditRentalContract({
  rentalContractId,
  canEdit = true,
}: ViewRentalContractProps) {
  useGate(Gate, { rentalContractId: rentalContractId })
  const { canRentalContractsUpdate } = usePermissions()

  const isRentalContractLoading = useStore(
    rentalContractModel.requestFx.pending,
  )
  const isSubmitting = useStore(updateFx.pending)

  const { canStatus } = useRentalContractMachineContext()

  const defaultValues = useStoreMap($rentalContract, (rentalContract) => {
    const carId = rentalContract?.getCarId()
    const workSchedule = rentalContract?.getWorkSchedule()
    const dayOff = rentalContract?.getDayOff()

    return {
      ...rentalContract?.getAttributes(),
      driverFullName: rentalContract?.getDriverFullName(),
      driverId: rentalContract?.getDriver()?.getApiId(),
      responsibleManager: rentalContract?.getResponsibleManager()?.getName(),
      carOption: carId
        ? ({
            id: carId,
            label: rentalContract?.getCarPlateNumber(),
          } as CarOptionForRentalContract)
        : null,
      workSchedule: workSchedule
        ? {
            id: workSchedule,
            label: workSchedule,
          }
        : null,
      dayOff: dayOff ? { id: dayOff, label: daysEnumLabels[dayOff] } : null,
      brand: rentalContract?.getCarBrandTitle(),
      model: rentalContract?.getCarModelTitle(),
      rental: rentalContract?.getCarRentalName(),
      workRule: rentalContract?.getWorkRuleTitle(),
      workRuleId: rentalContract?.getWorkRuleId(),
      rentalServicesOptions: (rentalContract?.getRentalServices() || []).map(
        (rentalService) => rentalService.getOption(),
      ),
      signedAt: rentalContract?.getSignedAt()
        ? formatDateTimeForUI(rentalContract?.getSignedAt())
        : '',
    }
  })

  return (
    <Form
      onSuccess={update}
      isSubmitting={isSubmitting}
      isLoading={isRentalContractLoading}
      defaultValues={defaultValues}
      canEdit={canEdit && canStatus?.formEdit && canRentalContractsUpdate}
      isBookedUntilVisible={canStatus.bookedUntilVisible}
    />
  )
}
