import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Skeleton,
} from '@mui/joy'
import { addDays, isValid } from 'date-fns'
import ru from 'date-fns/locale/ru'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { InputMaskElement } from 'imask'
import { ChangeEvent, forwardRef, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { uiDateTimeFormat } from '~/shared/config/datetime'
import { formatDateTimeForApi } from '~/shared/lib/date'
import { CalendarIcon } from '~/shared/ui/Icons'
import { Input } from '../ui/styled'
import { DateTimePickerPopper } from './styled'
import 'react-datepicker/dist/react-datepicker.css'

export type DateTimePickerInputProps = {
  name: string
  skeletonShow?: boolean
  label?: string
  maxDays?: number
} & Omit<InputProps, 'name' | 'slotProps'>

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const MaskAdapter = forwardRef<HTMLElement, CustomProps>(function Wrapper(
  { onChange, ...other },
  ref: InputMaskElement,
) {
  return (
    <IMaskInput
      {...other}
      mask='##.##.####, ##:##'
      definitions={{
        '#': /[0-9]/,
      }}
      onInput={(value: ChangeEvent<HTMLInputElement>) => onChange(value)}
      inputRef={ref}
      autoComplete='off'
    />
  )
})

export function DateTimePickerInput({
  name,
  skeletonShow,
  label,
  placeholder,
  maxDays,
  ...inputProps
}: DateTimePickerInputProps) {
  const {
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext()
  const selected = watch(name) || null

  const handleDateChange = (date: Date | null) => {
    setValue(name, isValid(date) ? formatDateTimeForApi(date as Date) : '')
  }

  const messageError = errors[name]?.message as string
  const isValidateError = Boolean(errors[name])

  watch((_, { name: watchName }) => {
    if (watchName === name && isValidateError) {
      trigger(name)
    }
  })

  const selectedDate = useMemo(
    () => (selected ? new Date(selected) : null),
    [selected],
  )

  return (
    <FormControl
      error={isValidateError}
      sx={{
        position: 'initial',
      }}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        popperContainer={DateTimePickerPopper}
        locale={ru}
        showTimeSelect
        timeFormat='p'
        timeIntervals={10}
        timeCaption='Время'
        dateFormat={uiDateTimeFormat}
        placeholderText={placeholder}
        disabledKeyboardNavigation
        readOnly={inputProps.readOnly}
        minDate={new Date()}
        maxDate={maxDays ? addDays(new Date(), maxDays) : null}
        customInput={
          <Input
            {...inputProps}
            startDecorator={<CalendarIcon />}
            name={name}
            fullWidth
            error={isValidateError}
            size='lg'
            slotProps={{
              ...(skeletonShow ? { root: { component: Skeleton } } : {}),
              input: {
                component: MaskAdapter,
                name,
              },
            }}
          />
        }
      />
      {isValidateError && (
        <FormHelperText data-testid={`error-message-${label}`}>
          {messageError}
        </FormHelperText>
      )}
    </FormControl>
  )
}
