import { Grid } from '@mui/joy'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { usePermissions } from '~/entities/viewer'
import { CarBrand, CarModel, Color, Subdivision } from '~/shared/api'
import {
  carPropertyTypeEnumOptions,
  transmissionEnumOptions,
} from '~/shared/config/constants'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  DatePickerInput,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { LocationAsyncAutocompleteInput } from './ui/LocationAsyncAutocompleteInput'
import { RegionAsyncAutocompleteInput } from './ui/RegionAsyncAutocompleteInput'

type MainProps = {
  showSkeleton?: boolean
  disabled?: boolean
  isEditing?: boolean
  isView?: boolean
}

export function Main({ showSkeleton, disabled, isEditing, isView }: MainProps) {
  const { canCarPropertyTypeUpdate } = usePermissions()

  const { watch, setValue } = useFormContext()

  const brand = watch('brand')
  const brandId = brand?.id

  const region = watch('regionId')
  const regionId = region?.id

  useEffect(() => {
    if (regionId && isEditing) {
      setValue('subdivisionId', null)
    }
    // eslint-disable-next-line
  }, [regionId])

  useEffect(() => {
    if (brandId && isEditing) {
      setValue('modelId', null)
    }
    // eslint-disable-next-line
  }, [brandId])

  return (
    <Container>
      <ContainerTitle>Основная информация</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='VIN номер'
            placeholder='Введите vin номер'
            name='vin'
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='Год выпуска'
            name='manufactureYear'
            placeholder='Введите год выпуска'
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <DatePickerInput
            label='Дата ввода в эксплуатацию'
            name='startExploitationDate'
            placeholder='Выберите дату ввода в эксплуатацию'
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <AsyncAutocompleteInput
            label='Марка'
            name='brand'
            placeholder='Выберите марку'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={CarBrand.fetchOptions}
          />
        </Grid>
        <Grid xs={3}>
          <RegionAsyncAutocompleteInput
            showSkeleton={showSkeleton}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <LocationAsyncAutocompleteInput
            showSkeleton={showSkeleton}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={2}>
          <AsyncAutocompleteInput
            label='Модель'
            name='modelId'
            placeholder='Выберите модель'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={(search) => CarModel.fetchOptions(search, brandId)}
            queryKey={brandId ? [brandId] : []}
          />
        </Grid>
        <Grid xs={2}>
          <AutocompleteInput
            label='Тип КПП'
            name='transmission'
            placeholder='Выберите тип КПП'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            options={transmissionEnumOptions}
          />
        </Grid>
        <Grid xs={2}>
          <AsyncAutocompleteInput
            label='Цвет'
            name='colorId'
            placeholder='Выберите цвет'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={Color.fetchOptions}
          />
        </Grid>
        <Grid xs={6}>
          <AsyncAutocompleteInput
            label='Подразделение'
            name='subdivisionId'
            placeholder='Выберите подразделение'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={(search) =>
              Subdivision.fetchOptionsWithRelations(search, regionId)
            }
            queryKey={regionId ? [regionId] : []}
          />
        </Grid>
        {isView && (
          <Grid xs={3}>
            <TextInput
              label='Гос. номер'
              name='plateNumber'
              placeholder='Гос. номер'
              skeletonShow={showSkeleton}
              readOnly
            />
          </Grid>
        )}
        <Grid xs={isView ? 3 : 6}>
          <AutocompleteInput
            label='Тип собственности'
            name='propertyType'
            placeholder='Выберите тип собственности'
            skeletonShow={showSkeleton}
            readOnly={disabled || !canCarPropertyTypeUpdate}
            options={carPropertyTypeEnumOptions}
            clearOnBlur
          />
        </Grid>
        <Grid xs={2}>
          <Switch
            label='Выкуп'
            name='isLeasingFullyPaid'
            formControlProps={{ sx: { marginTop: '40px' } }}
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={2}>
          <Switch
            label='ПредТотал'
            name='isPreTotaled'
            formControlProps={{ sx: { marginTop: '40px' } }}
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={2}>
          <Switch
            label='Участвует в акции'
            name='isPromotion'
            formControlProps={{ sx: { marginTop: '40px' } }}
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
