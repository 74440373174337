import { Box } from '@mui/joy'
import { useCallback, useState } from 'react'
import { CarAtWorkSubStatusEnum } from '~/shared/config/enums'
import { Modal } from '~/shared/ui/Modal'
import { Form, FormValues } from './Form'

type SuspendModalProps = {
  isShow: boolean
  onOk: (reasons: CarAtWorkSubStatusEnum) => Promise<void>
  onCancel: () => void
}

export const SuspendModal = ({ isShow, onOk, onCancel }: SuspendModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSuccess = useCallback(
    async ({ reason }: FormValues) => {
      try {
        setIsSubmitting(true)
        await onOk(reason)
        onCancel()
      } finally {
        setIsSubmitting(false)
      }
    },
    [onCancel, onOk],
  )

  return (
    <Modal
      isShow={isShow}
      title='Приостановление договора'
      onCancel={isSubmitting ? undefined : onCancel}
      dialogProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '600px',
        },
      }}
    >
      <Box sx={{ padding: '4px' }}>
        <Form
          onSuccess={handleSuccess}
          onCancel={onCancel}
          isSubmitting={isSubmitting}
        />
      </Box>
    </Modal>
  )
}
