import AddSharpIcon from '@mui/icons-material/AddSharp'
import { useSelector } from '@xstate/react'
import { useStore } from 'effector-react'
import { useCallback, useContext } from 'react'
import { CanceledModal } from '~/widgets/Driver/RentalContractControls/CanceledModal'
import { DriverMachineContext } from '~/entities/Driver'
import {
  getCanRentalContractMachine,
  rentalContractModel,
} from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { Alert } from '~/shared/ui/Alerts'
import { Button } from '~/shared/ui/Buttons'
import { useModal } from '~/shared/ui/Modal'
import { TerminateModal } from '../TerminateModal'
import {
  $activeRentalContract,
  $getRentalContractActiveIsLoading,
} from './model'
import { WarningCannotCreateModal } from './WarningCannotCreateModal'

export const RentalContractCreated = () => {
  const { canRentalContractsCreate } = usePermissions()
  const {
    isModalShow: isRentalContractTerminateModalShow,
    openModal: openRentalContractTerminateModal,
    closeModal: closeRentalContractTerminateModal,
  } = useModal()
  const {
    isModalShow: isRentalContractCanceledModalShow,
    openModal: openRentalContractCanceledModal,
    closeModal: closeRentalContractCanceledModal,
  } = useModal()
  const {
    isModalShow: isModalWarningShow,
    openModal: openModalWarning,
    closeModal: closeModalWarning,
  } = useModal()

  const machineCtx = useContext(DriverMachineContext)
  const can = useSelector(machineCtx.interpreter, (state) => ({
    create_rental_contract: state.can('create_rental_contract'),
  }))

  const getRentalContractActiveIsLoading = useStore(
    $getRentalContractActiveIsLoading,
  )
  const activeRentalContract = useStore($activeRentalContract)

  const handleRentalContractCreate = useCallback(async () => {
    if (!can.create_rental_contract) {
      openModalWarning()
      return
    }
    const rentalContractCanStatus = getCanRentalContractMachine(
      activeRentalContract?.getStatus(),
    )

    if (!activeRentalContract) {
      rentalContractModel.goToRentalContractNew()
      return
    }
    if (rentalContractCanStatus?.terminate) {
      openRentalContractTerminateModal()
      return
    }
    if (rentalContractCanStatus?.canceled) {
      openRentalContractCanceledModal()
      return
    }
  }, [
    activeRentalContract,
    can.create_rental_contract,
    openModalWarning,
    openRentalContractCanceledModal,
    openRentalContractTerminateModal,
  ])

  const handleRentalContractTerminate = useCallback(
    async (reasons: string[]) => {
      await rentalContractModel.terminateRentalContractFx({
        rentalContractId: activeRentalContract?.getApiId() as UniqueId,
        reasons,
      })
      rentalContractModel.goToRentalContractNew()
    },
    [activeRentalContract],
  )
  const handleRentalContractCanceled = useCallback(async () => {
    await rentalContractModel.canceledRentalContractFx(
      activeRentalContract?.getApiId() as UniqueId,
    )
    rentalContractModel.goToRentalContractNew()
  }, [activeRentalContract])

  return (
    <>
      <Button
        show={canRentalContractsCreate}
        onClick={handleRentalContractCreate}
        startDecorator={<AddSharpIcon />}
        variant='gray'
        loading={getRentalContractActiveIsLoading}
      >
        Создать новый договор
      </Button>

      <TerminateModal
        isShow={isRentalContractTerminateModalShow}
        onOk={handleRentalContractTerminate}
        extraBlock={
          <Alert variant='warning' sx={{ marginBottom: '24px' }}>
            При создании нового договора, действующий договор будет расторгнут!
          </Alert>
        }
        onCancel={closeRentalContractTerminateModal}
        buttonSuccessTitle='Расторгнуть и создать новый'
      />
      <CanceledModal
        isShow={isRentalContractCanceledModalShow}
        onOk={handleRentalContractCanceled}
        extraBlock={
          <Alert variant='warning' sx={{ margin: '44px 0 24px 0' }}>
            При создании нового договора, существующий договор будет
            аннулирован!
          </Alert>
        }
        onCancel={closeRentalContractCanceledModal}
        buttonSuccessTitle='Аннулировать и создать новый'
      />
      <WarningCannotCreateModal
        isShow={isModalWarningShow}
        onCancel={closeModalWarning}
      />
    </>
  )
}
