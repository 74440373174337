import * as z from 'zod'
import { Car } from '~/shared/api/car'
import { Driver } from '~/shared/api/driver'
import { Employee } from '~/shared/api/employee'
import { RentalService } from '~/shared/api/rentalService'
import {
  CarAtWorkSubStatusEnum,
  DayEnum,
  RentalContractStatusEnum,
  RentalContractWorkScheduleEnum,
} from '~/shared/config/enums'
import {
  dateSchema,
  currentDateTimeSchema,
  enumOptionSchema,
  uuidOptionSchema,
  uuidOptionsSchema,
} from '~/shared/lib/schemas'
import { ApiModel, ToManyRelation, ToOneRelation } from './core'

const relationsSchema = z.object({
  carOption: uuidOptionSchema,
  workRuleOption: uuidOptionSchema,
  rentalServicesOptions: uuidOptionsSchema,
  responsibleManager: z.string().optional(),
})
const attributeSchema = z.object({
  firstDayIsFree: z.boolean().optional().default(false),
  workSchedule: enumOptionSchema(RentalContractWorkScheduleEnum),
  rentPerDay: z.number().optional(),
  status: z.nativeEnum(RentalContractStatusEnum),
  bookedUntil: currentDateTimeSchema,
  terminationDate: dateSchema.optional(),
  contractNumber: z.string().optional(),
  carId: z.string().optional(),
  driverId: z.string(),
  carPlateNumber: z.string().optional(),
  carModelTitle: z.string().optional(),
  carBrandTitle: z.string().optional(),
  carRentalId: z.string().optional(),
  carRentalName: z.string().optional(),
  workRuleId: z.string().optional(),
  workRuleTitle: z.string().optional(),
  driverFullName: z.string().optional(),
  dayOff: enumOptionSchema(DayEnum).optional().nullable(),
  filepath: z.string(),
  signedAt: dateSchema.optional().nullable(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type PaymentsTemplateType = {
  time: string
  paymentTime: string
  sum: number
  id: UniqueId
}
export type PaymentsTemplateDataType = {
  paymentsTemplate: {
    data: PaymentsTemplateType[]
  }
  rentalContract: {
    contractNumber: string
    createdAt: string
    rentPerDay: string
    workSchedule: RentalContractWorkScheduleEnum
    dayOff: DayEnum | null
  }
}

export type RentalContractAttributes = z.infer<typeof attributeSchema>

export class RentalContract extends ApiModel<
  typeof schema,
  RentalContractAttributes
> {
  static jsonApiType = 'rental-contracts'

  static schema = schema

  getStatus(): RentalContractStatusEnum {
    return this.getAttribute('status')
  }
  getCarId(): UniqueId {
    return this.getAttribute('carId')
  }
  getCarPlateNumber(): string {
    return this.getAttribute('carPlateNumber')
  }
  getCarModelTitle(): string {
    return this.getAttribute('carModelTitle')
  }
  getCarBrandTitle(): string {
    return this.getAttribute('carBrandTitle')
  }
  getCarRentalId(): UniqueId {
    return this.getAttribute('carRentalId')
  }
  getCarRentalName(): string {
    return this.getAttribute('carRentalName')
  }
  getWorkRuleId(): UniqueId {
    return this.getAttribute('workRuleId')
  }
  getWorkRuleTitle(): string {
    return this.getAttribute('workRuleTitle')
  }
  getDriverId(): UniqueId {
    return this.getAttribute('driverId')
  }
  getDriverFullName(): string {
    return this.getAttribute('driverFullName')
  }
  getNumber(): string {
    return this.getAttribute('contractNumber')
  }
  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }
  getCarBrandAndModel(): string {
    return `${this.getCar()?.getAttributeBrand()} ${this.getCar()?.getModel()}`
  }
  getRentPerDay(): string {
    return this.getAttribute('rentPerDay')
  }
  getWorkSchedule(): RentalContractWorkScheduleEnum {
    return this.getAttribute('workSchedule')
  }
  getDayOff(): DayEnum {
    return this.getAttribute('dayOff')
  }
  getFilepath(): string {
    return this.getAttribute('filepath')
  }
  getSignedAt(): string {
    return this.getAttribute('signedAt')
  }
  getBookedUntil(): string {
    return this.getAttribute('bookedUntil')
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }
  setCar(id: UniqueId) {
    const car = new Car()
    car.setApiId(id)
    this.setRelation('car', car)
  }

  driver(): ToOneRelation<Driver, this> {
    return this.hasOne(Driver)
  }
  getDriver(): Driver {
    return this.getRelation('driver')
  }
  setDriver(id: UniqueId) {
    const driver = new Driver()
    driver.setApiId(id)
    this.setRelation('driver', driver)
  }

  responsibleManager(): ToOneRelation<Employee, this> {
    return this.hasOne(Employee)
  }
  getResponsibleManager(): Employee {
    return this.getRelation('responsibleManager')
  }
  setResponsibleManager(id: UniqueId) {
    const responsibleManager = new Employee()
    responsibleManager.setApiId(id)
    this.setRelation('responsibleManager', responsibleManager)
  }

  rentalServices(): ToManyRelation<RentalService, this> {
    return this.hasMany(RentalService)
  }
  getRentalServices(): RentalService[] {
    return this.getRelation('rentalServices')
  }
  setRentalServices(ids: UniqueId[]) {
    const rentalServices = ids.map((id) => {
      const rentalServices = new RentalService()
      rentalServices.setApiId(id as string)
      return rentalServices
    })
    this.setRelation('rentalServices', rentalServices)
  }

  async terminate(reasons: string[]) {
    const url = `${RentalContract.getJsonApiUrl()}/${this.getApiId()}/actions/terminate`
    return RentalContract.effectiveHttpClient.patch(url, reasons)
  }

  static async getRentPerDay(
    workRuleId: UniqueId,
    workSchedule: RentalContractWorkScheduleEnum,
  ): Promise<{ rentPerDay: number }> {
    const url = `${RentalContract.getJsonApiUrl()}/actions/calculate-rent-per-day/?workRuleId=${workRuleId}&workSchedule=${workSchedule}`
    const response = await RentalContract.effectiveHttpClient.get(url)
    return response.getData()
  }

  static async getPaymentSchedule(
    id: UniqueId,
  ): Promise<PaymentsTemplateDataType> {
    const url = `${RentalContract.getJsonApiUrl()}/actions/payments-template?id=${id}`
    const response = await RentalContract.effectiveHttpClient.get(url)
    return response.getData()
  }

  async suspend(reason: CarAtWorkSubStatusEnum) {
    const url = `${RentalContract.getJsonApiUrl()}/${this.getApiId()}/actions/suspend`
    return RentalContract.effectiveHttpClient.patch(url, { reason })
  }

  async continue() {
    const url = `${RentalContract.getJsonApiUrl()}/${this.getApiId()}/actions/continue`
    return RentalContract.effectiveHttpClient.patch(url)
  }

  async activate() {
    const url = `${RentalContract.getJsonApiUrl()}/${this.getApiId()}/actions/activate`
    return RentalContract.effectiveHttpClient.patch(url)
  }

  async signingCancel() {
    const url = `${RentalContract.getJsonApiUrl()}/${this.getApiId()}/actions/signing-cancel`
    return RentalContract.effectiveHttpClient.patch(url)
  }
}
