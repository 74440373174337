import * as z from 'zod'

import { Region } from '~/shared/api/index'
import { Option } from '~/shared/config/constants'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'

const relationsSchema = z.object({
  regionId: uuidOptionSchema,
})
const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
  address: z.string().trim().min(1, 'Обязательное поле'),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type LocationAttributes = z.infer<typeof attributeSchema>
export type LocationOption = Option & {
  filters: {
    regionId?: UniqueId
  }
}

export class Location extends ApiModel<typeof schema, LocationAttributes> {
  static jsonApiType = 'locations'

  static schema = schema

  region(): ToOneRelation<Region, this> {
    return this.hasOne(Region)
  }

  getRegion(): Region {
    return this.getRelation('region')
  }

  setRegion(id: UniqueId) {
    const region = new Region()
    region.setApiId(id)
    this.setRelation('region', region)
  }

  getName(): string {
    return this.getAttribute('name')
  }

  getOption(): LocationOption {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
      filters: {
        regionId: this.getRegion()?.getApiId(),
      },
    }
  }

  static async fetchOptions(search: string, regionId?: UniqueId) {
    let builder = Location.where('name', search).with('region')

    if (regionId) builder = builder.where('regionId', regionId)

    const response = await builder.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
